
import {
  Box, Flex, Text, Spinner, useToast, useDisclosure, Button, useColorModeValue
} from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { Card } from "../../components/Card"
import { ModalWrapper } from '../../components/ModalWrapper'
import { BaseButton } from '../../components/Buttons'
import { OrderInfo } from './OrderInfo'
import { api } from '../../services/api/api'
import { Context } from "../../Context/authContext";
import { formateDateAndTime } from "../../utils/formatDate";


export const MotoboySchedule = () => {
  const [spinner, setSpinner] = useState(true)
  const [sales, setSales] = useState([])
  const [salestoModal, setSalestoModal] = useState({})
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const { usuario } = useContext(Context)
  const formBackground = useColorModeValue('gray.900', 'gray.600');



  const handleShowAndEditModal = (item) => {
    setSalestoModal(item)
    onOpen()
  }


  const handleChangeValue = (e) => {
    setSalestoModal({ ...salestoModal, [e.target.name]: e.target.value })
  }

  // const GetAllSales = async () => {
  //   const response = await api.get(`/entregas-motoboy/${usuario._id}`)
  //   setSales(response.data)
  //   setSpinner(false)
  // }

  // useEffect(() => {
  //   GetAllSales()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const result = sales.filter(order => order._id !== salestoModal._id)

    try {
      await api.post(`/venda-realizada/${salestoModal._id}`, salestoModal)
      setSales(result)
      toast({
        title: `Venda enviada com sucesso`,
        status: 'success',
        isClosable: true,
      })
      setLoading(false)
      onClose()
    } catch (e) {
      setLoading(false)

      toast({
        title: `Ocorreu um erro ao enviar o recibo` + e,
        status: 'error',
        isClosable: true,
      })
      onClose()
    }

  }


  // console.log(sales)
  return (
    <Box>
      <Flex borderBottom="3px solid gray" alignItems='center' flexDir='column' justifyContent='center' mb={4}>
        <Text fontSize="24px">Entregas hoje</Text>
      </Flex>

      {spinner && (
        <Spinner />
      )}


      {sales && (
        <>

          {sales.map((item, key) => (
            <Card mb={6} key={key} >
              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='center' mb={4}>
                <Text fontSize="20px" whiteSpace='pre-wrap'><strong> {item.cliente} </strong></Text>
              </Flex>
              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start'>
                  <Text mr={4} fontSize="18px"><strong> Endereço</strong></Text>

                  <Text fontSize="16px" lineHeight="30px" whiteSpace='pre-wrap'>{item.endereco}</Text>

                </Flex>
              </Flex>

              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start'>
                  <Text mr={4} fontSize="18px"><strong>  Vendedor(a) </strong></Text>

                  <Text fontSize="16px" lineHeight="30px">{item.vendedor}</Text>

                </Flex>
              </Flex>



              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start'>
                  <Text mr={4} fontSize="18px"><strong>  Horario </strong></Text>

                  <Text fontSize="16px" lineHeight="30px">{formateDateAndTime(item.horario)}</Text>

                </Flex>
              </Flex>

              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start'>
                  <Text mr={4} fontSize="18px"><strong>Motoboy</strong>  </Text>

                  <Text fontSize="16px" lineHeight="30px"> {item.motoboy}</Text>



                </Flex>
              </Flex>

              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start'>
                  <Text mr={4} fontSize="18px">  <strong> Produto(s) </strong>  </Text>

                  {item.iphone && (
                    <Text mr={4} fontSize="16px" lineHeight="30px"> {`${item.iphone} - ${item.gigas} - ${item.cores}`}</Text>
                  )}

                  {item.apppleWatch && (
                    <Text mr={4} fontSize="16px" lineHeight="30px"> {`${item.apppleWatch} - ${item.MM}`}</Text>
                  )}

                  {item.airPods && (
                    <Text mr={4} fontSize="16px" lineHeight="30px"> {item.airPods}</Text>
                  )}


                </Flex>
              </Flex>

              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start' >

                  <Text fontSize="18px"> <strong> Observações </strong></Text>

                  {item.normal && (
                    <Text fontSize="16px" lineHeight="25px" whiteSpace='pre-wrap' > {item.observacoes}</Text>
                  )}

                  {item.troca && (
                    <>

                      <Text fontSize="16px" lineHeight="25px" whiteSpace='pre-wrap' > Este foi o aparelho da troca <strong>{item.aparelhoTroca}</strong> </Text>

                      <Text fontSize="16px" lineHeight="25px" whiteSpace='pre-wrap' > {item.obsevacoesTroca}</Text>
                    </>
                  )}


                </Flex>
              </Flex>



              <Flex borderBottom="3px solid gray" alignItems='center' justifyContent='flex-start' mb={4}>
                <Flex flexDir='column' alignItems='flex-start'>
                  <Text mr={4} fontSize="18px">  <strong>  Valor</strong> </Text>

                  {item.normal && (

                    <Text mr={4} fontSize="16px" lineHeight="30px">{item.valor}</Text>
                  )}

                  {item.troca && (
                    <>
                      <Text mr={4} fontSize="16px" lineHeight="30px">Esse foi o valor do acressimo</Text>
                      <Text mr={4} fontSize="16px" lineHeight="30px">{item.valorAcressimo}</Text>
                    </>
                  )}

                </Flex>
              </Flex>



              <Box>
                <Flex justifyContent="space-between">

                  <BaseButton firstLabel='Enviar' secondLabel='comprovante' fontSize='small' onClick={() => handleShowAndEditModal(item)} />


                  <a href={`https://api.whatsapp.com/send?phone=${item.telefone}&text=ol%C3%A0%2C%20sou%20o%20${item.motoboy}%2C%20sou%20responsavel%20pela%20entrega%20do%20seu%20Aparelho`} target='_blank' rel="noopener noreferrer">
                    <Button
                      background={formBackground}
                      cursor="pointer"
                      borderRadius="1.5rem"
                      fontSize='small'
                    >
                      <Text color='white'> WhatsApp do <br /> cliente</Text>
                    </Button>
                  </a>

                  {/* <BaseButton firstLabel='WhatsApp do' secondLabel='cliente' fontSize='small' onClick={() => handleShowAndEditModal(item)} /> */}

                </Flex>
              </Box>



            </Card>
          ))}

        </>


      )}



      <ModalWrapper isOpen={isOpen} size='xs' onClose={onClose}>
        <OrderInfo info={salestoModal} onClose={onClose} handleFormSubmit={handleFormSubmit} handleChangeValue={handleChangeValue} loading={loading} />
      </ModalWrapper>



    </Box >
  )
}
