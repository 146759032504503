
import { Flex, Text, Input, Button, InputGroup, Image, ModalContent, ModalBody } from '@chakra-ui/react';
import fastyLeads from '../../shared/assets/images/fastyLeads.svg';
import { useToast } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import emailIcon from '../../shared/assets/images/email.svg'
import passwordIcon from '../../shared/assets/images/password.svg'



import login3Icon from '../../shared/assets/images/login3.svg'
import VectorIcon from '../../shared/assets/images/Vector.svg'


import { Context } from '../../Context/authContext'
import { MotionFlex, containerFlex, itemAnimation } from '../../components/Animation/AnimationFlex';
import { ModalWrapper } from '../../components/ModalWrapper';

import { NewButton } from '../../components/Buttons/NewButton';
import { api } from '../../services/api/api';

export const Login = () => {
  const { handleLogin, loadingButton } = useContext(Context)
  const [showModal, setShowModal] = useState(false)
  const toast = useToast()
  const [user, setUser] = useState({
    email: '',
    password: ''
  })
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [emailRecover, setEmailRecover] = useState('')

  const [showPass, setShowPass] = useState(false)
  const handleChangeLogin = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }
  const handleClick = () => setShowPass(!showPass)


  const handleSendRecoverPassword = async () => {
    setLoadingEmail(true)
    try {

      await api.post('/reset', { email: emailRecover })
      setLoadingEmail(false)
      setShowModal(false)
      setEmailRecover('')
      return toast({
        title: `Email enviado com sucesso!`,
        status: 'success',
        isClosable: true,
      })


    } catch (err) {
      setLoadingEmail(false)

      return toast({
        title: `Ocorreu um erro ao enviar o email, entre em contato com o suporte da plataforma!`,
        status: 'error',
        isClosable: true,
      })
    }

  }

  const handleLogar = async () => {

    try {

      if (!user.email.trim()) {
        return toast({
          title: `Digite o E-mail!`,
          status: 'error',
          isClosable: true,
        })
      }

      if (!user.password.trim()) {
        return toast({
          title: `Digite sua senha`,
          status: 'error',
          isClosable: true,
        })
      }


      const response = await handleLogin(user)



      if (response === '' || response === undefined || response === false) {
        return toast({
          title: `Email ou senha incorretos`,
          status: 'error',
          isClosable: true,
        })
      }

    } catch (err) {


      return toast({
        title: `Email ou senha incorretos`,
        status: 'error',
        isClosable: true,
      })
    }


  }

  return (
    <MotionFlex
      variants={containerFlex}
      initial='hidden'
      animate='visible'
      w='100vw'
      h='100vh'
      p={'4rem'}
    >

      <Flex w={['100%', '100%', '100%', '50%']}  >



        <MotionFlex
          variants={itemAnimation}

          flexDir={'column'}
          justify={'center'}
          minH="100%"
          align={'center'}

          w='100%'
          p='5'
          borderRadius={'10px'}


        >
          <Flex
            flexDir={'column'}
            justify={'center'}
            align={'center'}
            gridGap='1rem'

          >


            <Flex w='100%' alignItems="center" flexDir={'column'} justifyContent="space-evenly" >
              <Flex >
                <Image src={fastyLeads} alt="teste" objectFit='cover' style={{ height: '120px' }} />
              </Flex>

              <Text maxW='350px' color='#979797' fontSize={'14px'}>
                Organize seus leads de forma rápida, facil e intuitiva. Tenha seu historico e diversas outras informações na palma da sua mão.
              </Text>

            </Flex>


            <Flex w='100%' flexDir={'column'} align={'center'} justify={'space-between'} gridGap='2rem'>


              <Flex align='flex-start' flexDir='column' minW={'350px'} >
                <Text fontSize={'md'} fontWeight='semibold'>Email </Text>
                <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                  <Input
                    border='none'
                    p='1'
                    pl='4'
                    h='10'
                    name='email'
                    value={user.email}
                    variant={'outline'}
                    placeholder='Digite seu email'
                    borderRadius='8px'
                    autocomplete="false"
                    focusBorderColor='none'
                    _focus={{
                      boxShadow: '0 0 0 0',
                      outline: 0
                    }}
                    onChange={handleChangeLogin}

                  />
                  <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center'>
                    <Text  > <Image src={emailIcon} height='20px' color='gray' /> </Text>
                  </Flex>

                </Flex>
              </Flex>

              <Flex align='flex-start' flexDir='column' w='100%'>
                <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                  <Text>Senha </Text>

                </Flex>
                <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                  <InputGroup size='md'>
                    <Input
                      border='none'
                      type={showPass ? 'text' : 'password'}
                      h='10'
                      p='1'
                      pl='4'
                      value={user.password}
                      name='password'
                      placeholder='**********************'
                      borderRadius='8px'
                      focusBorderColor='none'
                      _focus={{
                        boxShadow: '0 0 0 0',
                        outline: 0

                      }}
                      autocomplete="false"
                      onChange={handleChangeLogin}
                    />
                    <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                      <Text  > <Image src={passwordIcon} height='24px' color='gray' /></Text>
                    </Flex>

                  </InputGroup>

                </Flex>


              </Flex>

              <Flex w='100%' flexDir={'column'}>
                <Button
                  bg='#3CC68A'
                  w='50%'
                  isLoading={loadingButton}
                  _hover={{ background: '#008037' }}
                  onClick={handleLogar}
                  type='submit'
                >
                  Logar na conta
                  <Image ml='3' src={VectorIcon} />
                </Button>


                <Text
                  mt='2'
                  as='span'
                  onClick={() => setShowModal(!showModal)}
                  fontSize={'14px'}
                  _hover={{ color: 'blue.900' }}
                  textDecor='underline'
                  transition='ease-in 100ms'
                  cursor={'pointer'}>  Esqueceu a senha? </Text>


                <ModalWrapper isOpen={showModal} onClose={() => setShowModal(!showModal)}>
                  <ModalContent minW='500px' p='2' >


                    <ModalBody >

                      <Flex align={'center'} justify='center'>


                        <Flex align='flex-start' flexDir='column' w='70%' >
                          <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                            <Text>Email </Text>

                          </Flex>
                          <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                            <InputGroup size='md'>
                              <Input
                                border='none'
                                type={'text'}
                                h='10'
                                p='1'
                                pl='4'
                                value={emailRecover}
                                name='email'
                                placeholder='Email@Email.com'
                                borderRadius='8px'
                                focusBorderColor='none'
                                _focus={{
                                  boxShadow: '0 0 0 0',
                                  outline: 0

                                }}
                                autocomplete="false"
                                onChange={(e) => setEmailRecover(e.target.value)}
                              />


                            </InputGroup>
                          </Flex>
                        </Flex>

                        <NewButton
                          firstLabel='Recuperar senha'
                          isLoading={loadingEmail}
                          onClick={handleSendRecoverPassword}
                          w='30%'
                          mt='5'
                          ml='6'
                          h='10'
                          fontSize="sm"
                          type="submit"
                        />

                      </Flex>
                    </ModalBody>



                  </ModalContent>
                </ModalWrapper>


              </Flex>





            </Flex>
          </Flex>
        </MotionFlex>

      </Flex >


      <MotionFlex variants={itemAnimation} display={['none', 'none', 'none', 'flex']} w='50%' align={'center'} justify='center' >
        <Image src={login3Icon} height={['500px', '500px', '550px', '550px', '600px', '800px',]} objectFit={'cover'} />
      </MotionFlex>



    </MotionFlex >
  );
};
