import { Avatar, Box, Button, Flex, Icon, Input, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Select, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { MdSystemUpdateAlt } from 'react-icons/md'
import { itemAnimation, MotionFlex } from '../../components/Animation/AnimationFlex'
import { InputLabel } from '../../components/form/InputLabel'
import { Context } from '../../Context/authContext'
import bgImage from '../../shared/assets/images/BgSignUp.png'
import { Radio, RadioGroup } from '@chakra-ui/react'
import { GiUpgrade } from 'react-icons/gi'
import { ModalWrapper } from '../../components/ModalWrapper'
import { BaseButton } from '../../components/Buttons';
import CurrencyInput from 'react-currency-input-field';
import { formatToCapitalized, formatToPhone } from 'brazilian-values'
import { api } from '../../services/api/api'
import { formatCurrencyWhileTyping } from '../../utils/formatCurrencyWhileTyping'
import { data } from './data'
import { CheckCircleIcon } from '@chakra-ui/icons'

export const Perfil = () => {
  const { user, setUser } = useContext(Context)
  const toast = useToast()
  const [editPerfil, setEditPerfil] = useState(false)
  const [loadingNotifications, setLoadingNotifications] = useState(false)
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)

  const [loadingEditUser, setLoadingEditUser] = useState(false)




  const [notification, setNotification] = useState({
    id: user.notification.id,
    userid: user.id,
    minvalue: user.notification.minvalue,
    maxvalue: user.notification.maxvalue,
    minpercent: user.notification.minpercent,
    maxpercent: user.notification.maxpercent,
    minprofit: user.notification.minprofit,
    status: user.notification.status
  })

  const handleUpdateNotification = async () => {



    // 'value' || value === 'percent profit inactive
    setLoadingNotifications(true)
    if (value === 'value') {

      const minvalue = notification.minvalue.replace(/[^\d]+/g, '')
      notification.minvalue = Number(minvalue)

      const maxvalue = notification.maxvalue.replace(/[^\d]+/g, '')
      notification.maxvalue = Number(maxvalue)

      const objectToBack = {
        id: user.notification.id,
        userid: user.id,
        minvalue: String(minvalue),
        maxvalue: String(maxvalue),
        minpercent: '0',
        maxpercent: '0',
        minprofit: '0',
        status: value
      }
      try {
        const response = await api.patch('/notifications', objectToBack)
        localStorage.removeItem('user')
        localStorage.setItem('user', JSON.stringify({ ...user, notification: response.data.data[0] }))
        setUser({ ...user, notification: response.data.data[0] })
        setNotification(response.data.data[0])
        toast({
          title: 'Dados atualizados com sucesso!',
          status: 'success',
          isClosable: true,
        })
      } catch (err) {
        toast({
          title: 'Ocorreu um erro ao atualizar os dados! Por favor, entre em contato com o suporte',
          status: 'success',
          isClosable: true,
        })
      }
    }

    if (value === 'percent') {

      const objectToBack = {
        id: user.notification.id,
        userid: user.id,
        minvalue: '0',
        maxvalue: '0',
        minpercent: notification.minpercent,
        maxpercent: notification.maxpercent,
        minprofit: '0',
        status: value
      }
      try {
        const response = await api.patch('/notifications', objectToBack)
        localStorage.removeItem('user')
        localStorage.setItem('user', JSON.stringify({ ...user, notification: response.data.data[0] }))
        setUser({ ...user, notification: response.data.data[0] })
        setNotification(response.data.data[0])
        toast({
          title: 'Dados atualizados com sucesso!',
          status: 'success',
          isClosable: true,
        })
      } catch (err) {
        toast({
          title: 'Ocorreu um erro ao atualizar os dados! Por favor, entre em contato com o suporte',
          status: 'success',
          isClosable: true,
        })
      }
    }



    if (value === 'profit') {

      const minprofit = notification.minprofit.replace(/[^\d]+/g, '')
      notification.minprofit = Number(minprofit)

      const objectToBack = {
        id: user.notification.id,
        userid: user.id,
        minvalue: '0',
        maxvalue: '0',
        minpercent: '0',
        maxpercent: '0',
        minprofit: String(minprofit),
        status: value
      }
      try {
        const response = await api.patch('/notifications', objectToBack)
        localStorage.removeItem('user')
        localStorage.setItem('user', JSON.stringify({ ...user, notification: response.data.data[0] }))
        setUser({ ...user, notification: response.data.data[0] })
        setNotification(response.data.data[0])

        toast({
          title: 'Dados atualizados com sucesso!',
          status: 'success',
          isClosable: true,
        })

      } catch (err) {
        toast({
          title: 'Ocorreu um erro ao atualizar os dados! Por favor, entre em contato com o suporte',
          status: 'success',
          isClosable: true,
        })
      }
    }
    setLoadingNotifications(false)
  }



  const [editUser, setEditUser] = useState({
    id: user.id,
    name: user.name || '',
    email: user.email || '',
    nationality: user.nationality || '',
    phone: user.phone || '',
    telegramId: user.telegramId || ''
  })


  const handleChangeValue = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value })
  }

  const handleChangeNotificationValue = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value })
  }



  const handleupdateUser = async (e) => {
    setLoadingEditUser(true)
    e.preventDefault()

    try {
      const response = await api.patch(`/user`, editUser)
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify({
        ...user,
        email: response.data.data[0].email,
        id: response.data.data[0].id,
        name: response.data.data[0].name,
        nationality: response.data.data[0].nationality,
        phone: response.data.data[0].phone,
        status: response.data.data[0].status,
        type: response.data.data[0].type,
      }))
      setUser({
        ...user,
        email: response.data.data[0].email,
        id: response.data.data[0].id,
        name: response.data.data[0].name,
        nationality: response.data.data[0].nationality,
        phone: response.data.data[0].phone,
        status: response.data.data[0].status,
        type: response.data.data[0].type,
      })
      toast({
        title: 'Dados atualizandos com sucesso',
        status: 'success',
        isClosable: true,
      })
      setEditPerfil(!editPerfil)

    } catch (err) {
      toast({
        title: 'Ocorreu um erro ao atualizar os dados! Por favor, entre em contato com o suporte',
        status: 'success',
        isClosable: true,
      })
    }
    setLoadingEditUser(false)

  }

  const bg = useColorModeValue(
    'gray.100',
    'gray.800'
  )

  const bgModal = useColorModeValue(
    "gray.200", 'gray.900'
  )

  const formBackground = useColorModeValue('rgba(255,255,255,0.8)', 'linear-gradient( 112.83deg , rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)');
  const [value, setValue] = useState(user.notification.status ? user.notification.status : 'value')


  const bgCards = useColorModeValue(
    'gray.100',
    'gray.800'
  )







  const button = useColorModeValue(
    '#FDFEFE',
    '#171821'
  )

  return (
    <MotionFlex
      variants={itemAnimation}
      flexDir={'column'}
      initial='hidden'

      animate='visible'
    >


      <MotionFlex
        id='divParaTerUmTamanhoEmBaixo'
        variants={itemAnimation}
        initial='hidden'

        flexDir='column'
        animate='visible'
        h={['280px', '280px', '166px']}
      >

        <MotionFlex
          id='backGroundImage'
          variants={itemAnimation}
          initial='hidden'
          animate='visible'
          bgImage={bgImage}
          w='100%'
          h='120px'
          borderRadius='25px'
          backgroundPosition={'50% center'}
          backgroundRepeat={'no-repeat'}
          position={'relative'}
          webkit-box-pack='center'
          justify={'center'}
        >

          <MotionFlex
            id='basicData'
            variants={itemAnimation}
            initial='hidden'
            animate='visible'
            top='40px'
            webkit-box-align='center'
            align={'center'}
            marginInline={'1.5rem'}
            maxH='330px'
            backdropFilter={'saturate(200%) blur(50px)'}
            position={'absolute'}
            shadow={'rgb(0 0 0 / 2%) 0px 5.5px'}
            p='14px'
            justify='space-between'
            gridGap='3'
            flexDir={['column', 'column', 'row']}
            w='90%'
            borderRadius={'20px'}
            border='1px solid rgba(255, 255, 255, 0.31)'
            // borderColor='#ffffff'
            // border='3px solid '
            bg={formBackground}

          >

            <MotionFlex
              id='perfil'
              variants={itemAnimation}
              initial='hidden'
              animate='visible'
              gridGap='3' flexDir={['column', 'column', 'row']} align='center' justify={'center'} >
              <Avatar size='md' name={user.name} />
              <Flex >

                <Box textAlign={['center', 'center', 'left']}>
                  <Text fontWeight={'bold'} fontSize='xl'> {user.name} </Text>
                  <Text opacity={'0.5'}>{user.type}</Text>
                  <Text opacity={'0.5'}> {user.email}</Text>
                </Box>
              </Flex>
            </MotionFlex>


            <MotionFlex
              id='buttons'
              variants={itemAnimation}
              initial='hidden'
              animate='visible'
              gridGap={5} align={'center'} justify={'space-between'}>

              <Button onClick={() => setEditPerfil(!editPerfil)} variant={'outline'} fontSize={'sm'} transition={'ease-in 0.2s'} _hover={{ filter: "brightness(70%)" }}>
                <Icon as={MdSystemUpdateAlt} fontSize='md' mr='2' />
                Atualizar perfil
              </Button>

              <Button onClick={() => setOpenModalUpgrade(!openModalUpgrade)} variant={'outline'} fontSize={'sm'} transition={'ease-in 0.2s'} _hover={{ filter: "brightness(70%)" }}>
                <Icon as={GiUpgrade} fontSize='md' mr='2' />
                Upgrade
              </Button>

            </MotionFlex>
          </MotionFlex>
        </MotionFlex>
      </MotionFlex>


      <MotionFlex
        id='wraperConteudos'
        variants={itemAnimation}
        initial='hidden'
        animate='visible'
        w='100%'
        mb='5'
        gridGap='5'
        align={['center', 'center', 'space-evenly']}
        flexDir={['column', 'column', 'row']}
        justify={['center', 'center', 'space-evenly']}
      >

        <MotionFlex
          id='wraperCardNotification'
          bg={bgCards}
          p={5}
          borderRadius='9px'
          flexDir='column'
          w='350px'
          h='300px'

          gridGap='5'
        >
          <Flex>
            <Text fontWeight={'bold'} fontSize={'lg'} overflowWrap={'break-word'}>
              Notificações
            </Text>
          </Flex>


          <Flex flexDir={'column'} gridGap={2} >
            <Text fontSize={'14px'} color='gray.500' fontWeight={'bold'}>
              Modo:
            </Text>

            <RadioGroup onChange={setValue} value={value}>
              <Stack direction='row' >
                <Radio value='value' isChecked >Valor</Radio>
                <Radio value='percent'>Percentual</Radio>
              </Stack>
            </RadioGroup>

          </Flex>



          <Flex gridGap={6} textAlign={'left'} justify={'space-between'} h='100%' flexDir={'column'} w='100%'>
            {value === 'value' || value === 'percent' ? (
              <>
                <Flex align='center' justify={'center'} gridGap={'6'} >
                  <Text fontSize={'14px'} w='30%' color='gray.500'  >Minímo: </Text>


                  <Input variant='flushed' value={value === 'value' ? formatCurrencyWhileTyping(notification.minvalue) : notification.minpercent} h='6' px='1' maxLength={12} name={value === 'value' ? 'minvalue' : 'minpercent'} onChange={handleChangeNotificationValue} placeholder='Digite o valor' />
                </Flex>

                <Flex align='center' justify={'center'} gridGap={'6'} >
                  <Text fontSize={'14px'} w='30%' color='gray.500'  >Máximo: </Text>
                  <Input variant='flushed' h='6' maxLength={12} value={value === 'value' ? formatCurrencyWhileTyping(notification.maxvalue) : notification.maxpercent} px='1' name={value === 'value' ? 'maxvalue' : 'maxpercent'} onChange={handleChangeNotificationValue} placeholder='Digite o valor' />
                </Flex>

              </>
            ) : (
              <>
              </>
            )}

            <Button
              bg={button}
              _hover={{ filter: "brightness(70%)" }}
              transition={'ease-in 0.2s'}
              type='submit'
              fontSize={'14px'}
              isLoading={loadingNotifications}
              onClick={handleUpdateNotification}
            >
              Confirmar alterações
            </Button>
          </Flex>


        </MotionFlex>

        <MotionFlex
          id='wraperCardPerfil'
          bg={bgCards}
          p={5}
          borderRadius='9px'
          h='300px'
          flexDir='column'
          w='350px'
          gridGap='5'
        >

          <Flex>
            <Text fontWeight={'bold'} fontSize={'lg'} overflowWrap={'break-word'}>
              Dados Pessoais
            </Text>
          </Flex>

          <Stack fontSize={'14px'} spacing={4}>
            <Flex gridGap={2}>
              <Text fontWeight={'bold'}>
                Nome:
              </Text>
              <Text color='gray.500' isTruncated fontWeight={'normal'} >
                {formatToCapitalized(user.name, {
                  trimTrailingWhiteSpaces: false
                })}
              </Text>
            </Flex>

            <Flex gridGap={2}>
              <Text fontWeight={'bold'}>
                E-mail:
              </Text>
              <Text color='gray.500' isTruncated fontWeight={'normal'} >
                {user.email}
              </Text>
            </Flex>

            <Flex gridGap={2}>
              <Text fontWeight={'bold'}>
                Nacionalidade:
              </Text>
              <Text color={user.nationality ? 'gray.500' : 'red.400'} isTruncated fontWeight={'normal'} >
                {user.nationality || "Pendente"}
              </Text>
            </Flex>

            <Flex gridGap={2}>
              <Text fontWeight={'bold'}>
                Celular:
              </Text>
              <Text color={user.phone ? 'gray.500' : 'red.400'} isTruncated fontWeight={'normal'} >
                {user.phone ? formatToPhone(user.phone) : 'Pendente'}
              </Text>
            </Flex>


            <Flex gridGap={2}>
              <Text fontWeight={'bold'}>
                Telegram Id:
              </Text>
              <Text color={user.telegramId ? 'gray.500' : 'red.400'} isTruncated fontWeight={'normal'} >
                {user.telegramId || 'Pendente'}
              </Text>
            </Flex>

            <Flex gridGap={2}>
              <Text fontWeight={'bold'}>
                Ativar notificações - <Text as='span' color='blue.200' _hover={{ color: 'blue.800' }} transition='ease-in 100ms' cursor={'pointer'}>  Clique Aqui</Text>
              </Text>

            </Flex>
          </Stack>
        </MotionFlex>

      </MotionFlex>


      <ModalWrapper isOpen={editPerfil} onClose={() => setEditPerfil(!editPerfil)}>
        <ModalContent bg={bg} maxW={['380px', '450px', '550px',]}>
          <ModalHeader >Editar Dados</ModalHeader>
          <ModalCloseButton />
          <form autoComplete='off' onSubmit={handleupdateUser}>
            <ModalBody>
              <Stack spacing={3} >
                <InputLabel label='Nome:' placeholder='Digite seu nome completo' valueInformation={editUser.name} nameInfo='name' onChange={handleChangeValue} />

                <Flex gridGap={6} flexDir={['column', 'column', 'row']}>
                  <InputLabel label='E-mail:' placeholder='Digite o nome do produto RESUMIDO' valueInformation={editUser.email} nameInfo='email' onChange={handleChangeValue} />

                  <Select placeholder={editUser.nationality ? editUser.nationality : 'Selecione uma opção'} name='nationality' border='2px' borderColor='gray' h='7' fontSize={'smaller'} p='0' mt='6' onChange={handleChangeValue}>
                    {data.map((item) => (
                      <option value={item.value}>{item.value}</option>
                    ))}

                  </Select>
                </Flex>

                <Flex gridGap={6} flexDir={['column', 'column', 'row']}>
                  <InputLabel label='Celular:' valueInformation={editUser.phone} placeholder='Digite o nome do produto RESUMIDO' nameInfo='phone' onChange={handleChangeValue} />

                  <InputLabel label='TelegramId:' valueInformation={editUser.telegramId} opacity='0.5' isReadOnly placeholder='Digite o nome do produto RESUMIDO' nameInfo='name' />
                </Flex>
              </Stack>

            </ModalBody>

            <ModalFooter justifyContent='flex-end'>
              <BaseButton firstLabel='Atualizar dados' isLoading={loadingEditUser} h='8' fontSize="sm" type="submit" />
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalWrapper>




      <ModalWrapper isOpen={openModalUpgrade} onClose={() => setOpenModalUpgrade(!openModalUpgrade)}>
        <ModalContent
          bg={bg}
          w='100%'
          // maxW={['380px', '450px', '800px', '1050px',]}
          p='5'>

          <MotionFlex
            initial='hidden'
            animate='visible'
            variants={itemAnimation}
            w='100%' mb='5' align={'center'} justify={'center'} flexDir={'column'} fontWeight={'bold'} fontSize={'lg'}>

            <Text maxW="310px" textAlign={'center'}>
              Planos
            </Text>
            <RadioGroup onChange={setValue} value={value}>
              <Stack direction='row' >
                <Radio value='value' isChecked >Mensal</Radio>
                <Radio value='value' isChecked >Trimestral</Radio>
                <Radio value='percent'>Anual</Radio>
              </Stack>
            </RadioGroup>

          </MotionFlex>

          <ModalCloseButton />
          <form autoComplete='off' onSubmit={handleupdateUser}>
            <ModalBody>


              <MotionFlex
                w='100%'
                initial='hidden'
                animate='visible'
                layout
                variants={itemAnimation}
                flexDir={['column', 'column', 'row']}
                maxH='550px'
                overFlow='auto'
                align='center'
                justify='center'
                // flexDir='column'
                gridGap={9}
              >


                <MotionFlex
                  bg={bgModal}
                  initial='hidden'
                  animate='visible'
                  variants={itemAnimation}
                  layout
                  borderRadius={9}
                  fontSize='small'
                  // onClick={() => handleEditProd(product)}
                  p='3'
                  boxShadow={`4px 4px 4px #000000`}
                  cursor='pointer'
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.9 }}
                  gridGap={4}
                  h='270px'
                  direction='column'
                  align='center'
                  justify='space-between'
                  w={'320px'}
                  border='1px solid gray'
                >

                  <MotionFlex
                    // bg={bg}
                    initial='hidden'
                    animate='visible'
                    layout
                    variants={itemAnimation}
                    flexDir='column'
                    alignItems='flex-start'

                    gridGap={2}
                  // onClick={() => handleEditProd(product)}
                  >

                    <Flex align={'center'} justify='center' w='100%' mb='3'>
                      <Text fontWeight={'bold'} fontSize='md'>Com esse plano você tem</Text>
                    </Flex>

                    <Flex align={'center'} justify='center' gridGap={1}>
                      <Icon as={CheckCircleIcon} fontSize='smaller' color='green.200' />
                      <Text color='gray.300'>Quantidade de produtos ilimitada</Text>
                    </Flex>

                    <Flex align={'center'} justify='center' gridGap={1}>
                      <Icon as={CheckCircleIcon} fontSize='smaller' color='green.200' />
                      <Text color='gray.300'>Acesso a historico completo dos produtos</Text>
                    </Flex>
                    <Flex align={'center'} justify='center' gridGap={1}>
                      <Icon as={CheckCircleIcon} fontSize='smaller' color='green.200' />
                      <Text color='gray.300'>Relatorio diário de produtos recomendados</Text>
                    </Flex>





                  </MotionFlex>


                </MotionFlex>
                {/* <MotionFlex
                  // bg={bg}
                  initial='hidden'
                  animate='visible'
                  layout
                  borderRadius={9}
                  fontSize='small'
                  // onClick={() => handleEditProd(product)}
                  p='3'
                  boxShadow={`4px 4px 4px #000000`}
                  cursor='pointer'
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.9 }}
                  gridGap={4}
                  h='270px'
                  direction='column'
                  align='center'
                  justify='space-between'
                  w={'320px'}
                  border='1px solid gray'
                >

                  <MotionFlex
                    // bg={bg}
                    initial='hidden'
                    animate='visible'
                    layout
                    flexDir='column'
                    gridGap={2}
                  // onClick={() => handleEditProd(product)}
                  >
                    <Text fontWeight={'bold'}>Plano Trimestral</Text>
                    <Text fontWeight={'semibold'} color='gray.300'>O plano inicial para testar a plataforma</Text>
                  </MotionFlex>


                </MotionFlex> */}
                {/* <MotionFlex
                  // bg={bg}
                  initial='hidden'
                  animate='visible'
                  layout
                  borderRadius={9}
                  fontSize='small'
                  // onClick={() => handleEditProd(product)}
                  p='3'
                  boxShadow={`4px 4px 4px #000000`}
                  cursor='pointer'
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.9 }}
                  gridGap={4}
                  h='270px'
                  direction='column'
                  align='center'
                  justify='space-between'
                  w={'320px'}
                  border='1px solid gray'
                >

                  <MotionFlex
                    // bg={bg}
                    initial='hidden'
                    animate='visible'
                    layout
                    flexDir='column'
                    gridGap={2}
                  // onClick={() => handleEditProd(product)}
                  >
                    <Text fontWeight={'bold'}>Plano Anual</Text>
                    <Text fontWeight={'semibold'} color='gray.300'>O plano inicial para testar a plataforma</Text>
                  </MotionFlex>


                </MotionFlex> */}

              </MotionFlex>
            </ModalBody>

            {/* <ModalFooter justifyContent='flex-end'>
              <BaseButton firstLabel='Atualizar dados' isLoading={loadingEditUser} h='8' type="submit" />
            </ModalFooter> */}
          </form>
        </ModalContent>
      </ModalWrapper>

    </MotionFlex >


  )
}
