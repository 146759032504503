import { useState } from 'react';

import { Flex, Text, Input, Button, InputGroup, Image, Tooltip, Box } from '@chakra-ui/react';
import fastyLeads from '../../../../shared/assets/images/fastyLeads.svg';
import { useToast } from '@chakra-ui/react';

import passwordIcon from '../../../../shared/assets/images/password.svg'


import { useParams } from 'react-router-dom';
import login3Icon from '../../../../shared/assets/images/login3.svg'
import VectorIcon from '../../../../shared/assets/images/Vector.svg'



import { MotionFlex, containerFlex, itemAnimation } from '../../../../components/Animation/AnimationFlex';
import { useEffect } from 'react';
import { api } from '../../../../services/api/api';
import history from '../../../../services/history';

export const NewPassWord = () => {

  const { hash } = useParams()
  const toast = useToast()
  const [loadingButton, setLoadingButton] = useState(false)
  const [user, setUser] = useState({
    password: '',
    newPassword: ''
  })
  console.log(hash)
  const [showPass, setShowPass] = useState(false)
  const [showInputs, setShowInputs] = useState(true)

  const handleChangeLogin = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const handleClick = () => setShowPass(!showPass)


  useEffect(() => {
    handleCheckIfHashIsValid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  const handleCheckIfHashIsValid = async () => {

    try {
      await api.post('/reset/validate', { hash: hash })
      setShowInputs(true)
    } catch (err) {
      setShowInputs(false)
    }

  }

  const handleLogar = async () => {
    setLoadingButton(true)
    try {

      if (!user.password.trim()) {
        setLoadingButton(false)
        return toast({
          title: `Digite a senha!`,
          status: 'error',
          isClosable: true,
        })
      }

      if (!user.newPassword.trim()) {
        setLoadingButton(false)
        return toast({
          title: `Digite sua nova senha`,
          status: 'error',
          isClosable: true,
        })
      }



      if (user.newPassword !== user.password) {
        setLoadingButton(false)
        return toast({
          title: `As senhas não coincidem, por favor revise a senha`,
          status: 'error',
          isClosable: true,
        })
      }



      const response = await api.post('/reset/confirm', { hash: hash, password: user.newPassword })


      if (response.data.data === false) {
        setLoadingButton(false)
        return toast({
          title: `Ocorreu um erro ao trocar a senha, entre em contato com o suporte`,
          status: 'error',
          isClosable: true,
        })
      }


      history.push('/')
      setLoadingButton(false)
      return toast({
        title: `Senha alterada com sucesso`,
        status: 'success',
        isClosable: true,
      })


    } catch (err) {

      setLoadingButton(false)
      return toast({
        title: `Ocorreu um erro ao trocar a senha, entre em contato com o suporte`,
        status: 'error',
        isClosable: true,
      })
    }


  }

  return (
    <MotionFlex
      variants={containerFlex}
      initial='hidden'
      animate='visible'
      w='100vw'
      h='100vh'
      p={'2rem'}
    >


      <Flex w={['100%', '100%', '100%', '50%']}  >



        <MotionFlex
          variants={itemAnimation}

          flexDir={'column'}
          justify={'center'}
          minH="100%"
          align={'center'}

          w='100%'

          borderRadius={'10px'}


        >
          <Flex
            flexDir={'column'}
            justify={'center'}
            align={'center'}
            gridGap='1rem'
          >
            <Flex w='100%' alignItems="center" flexDir={'column'} justifyContent="space-evenly" >
              <Flex >
                <Image src={fastyLeads} alt="teste" style={{ height: '120px' }} />
              </Flex>


              {showInputs ? (
                <Text maxW='350px' color='#979797' fontSize={'14px'}>
                  Crie sua nova senha e utilize para acessar todas as funcionalidades.
                </Text>
              ) : (
                <Text mt='15' color='#979797' fontSize={'22px'}>
                  Link para redefinição
                  de senha inválido.
                </Text>
              )}


            </Flex>


            {showInputs && (


              <Flex w='100%' flexDir={'column'} align={'center'} justify={'space-between'} gridGap='1rem'>



                <Flex align='flex-start' flexDir='column' w='100%'>
                  <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                    <Text>Nova senha </Text>

                  </Flex>
                  <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                    <InputGroup size='md'>
                      <Input
                        border='none'
                        type={showPass ? 'text' : 'password'}
                        h='10'
                        p='1'
                        pl='4'
                        value={user.password}
                        name='password'
                        placeholder='**********************'
                        borderRadius='8px'
                        autocomplete="false"
                        onChange={handleChangeLogin}
                        focusBorderColor='none'
                        _focus={{
                          boxShadow: '0 0 0 0',
                          outline: 0

                        }}
                      />
                      <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                        <Text  >    <Tooltip label={!showPass ? 'Mostrar senha' : 'Ocultar senha'}>
                          <Box>
                            <Image src={passwordIcon} height='24px' color='gray' />
                          </Box>
                        </Tooltip></Text>
                      </Flex>

                    </InputGroup>

                  </Flex>


                </Flex>


                <Flex align='flex-start' flexDir='column' w='100%'>
                  <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                    <Text>Confirmação de nova senha </Text>

                  </Flex>
                  <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                    <InputGroup size='md'>
                      <Input
                        border='none'
                        type={showPass ? 'text' : 'password'}
                        h='10'
                        p='1'
                        pl='4'
                        value={user.newPassword}
                        name='newPassword'
                        placeholder='**********************'
                        borderRadius='8px'
                        autocomplete="false"
                        onChange={handleChangeLogin}
                        focusBorderColor='none'
                        _focus={{
                          boxShadow: '0 0 0 0',
                          outline: 0

                        }}
                      />
                      <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                        <Text  >
                          <Tooltip label={!showPass ? 'Mostrar senha' : 'Ocultar senha'}>
                            <Box>
                              <Image src={passwordIcon} height='24px' color='gray' />
                            </Box>
                          </Tooltip>
                        </Text>
                      </Flex>

                    </InputGroup>

                  </Flex>


                </Flex>




                <Flex w='100%' flexDir={'column'}>
                  <Button
                    bg='#71F697'
                    w='50%'

                    isLoading={loadingButton}
                    _hover={{ background: '#008037' }}
                    onClick={handleLogar}
                    type='submit'
                  >
                    Cadastrar senha
                    <Image ml='3' src={VectorIcon} />
                  </Button>



                </Flex>





              </Flex>

            )}
          </Flex>
        </MotionFlex>

      </Flex >


      <MotionFlex variants={itemAnimation} display={['none', 'none', 'none', 'flex']} w='50%' align={'center'} justify='center' >
        <Image src={login3Icon} height={['500px', '500px', '550px', '550px', '600px', '800px',]} objectFit={'cover'} />
      </MotionFlex>



    </MotionFlex >
  );
};
