import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        fontFamily: "body",
        color: mode("#000000", "#000000")(props),
        bg: mode("#F8F8F8", "#F8F8F8")(props),
        //    bg: mode("#F1F8F8", "##333333")(props),
        lineHeight: "base",
      },

    }),
  },
})
