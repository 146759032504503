import { Flex, Text, useColorModeValue, Icon } from "@chakra-ui/react"
import { formatToCapitalized } from "brazilian-values"
import { formatCurrencyWhileTyping } from "../../../utils/formatCurrencyWhileTyping";
import { MdMoneyOff, MdAttachMoney } from 'react-icons/md'
import { AiOutlineRise, AiOutlineFall } from 'react-icons/ai'
import Chart from 'react-apexcharts'

import { MotionFlex, containerFlex, itemAnimation } from '../../../components/Animation/AnimationFlex'
import { useState } from "react";


export const CardProduct = ({ product, handleEditProd }) => {
  const bg = useColorModeValue(
    'gray.100',
    'gray.800'
  )

  const [barChartData] = useState([
    {
      name: "Sales",
      data: [330, 250, 110, 300, 490, 350, 270, 130, 425],
    },
  ]);

  const [barChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          backgroundColor: "red",
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      show: false,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "#fff",
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: "#fff",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "12px",
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
            },
          },
        },
      },
    ],
  });

  const bGraph = useColorModeValue(
    'linear-gradient(81.62deg, #4a548a 2.25%, #29314f 79.87%)',
    'linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)'
  )


  const shaddow = useColorModeValue(
    '#7c7c99',
    '#2d2d33'
  )



  return (
    <>
      <MotionFlex
        bg={bg}
        variants={containerFlex}
        initial='hidden'
        animate='visible'
        layout
        borderRadius={9}
        fontSize='small'
        onClick={() => handleEditProd(product)}
        p='3'
        boxShadow={`4px 4px 4px ${shaddow}`}
        cursor='pointer'
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.9 }}
        gridGap={4}
        h='280px'
        direction='column'
        w={['320px', '300px', '280px', '300px', '306px', '275px']}
      >

        <MotionFlex
          id='header'
          variants={itemAnimation}
          initial='hidden'

          borderRadius='5'
          animate='visible'
          py="1rem"
          height={"140px"}
          width="100%"
          bg={bGraph}
          position="relative"
        >

          <Chart
            options={barChartOptions}
            series={barChartData}
            type="bar"
            width="100%"
            height="100%"

          />

        </MotionFlex>

        <MotionFlex
          variants={itemAnimation}
          initial='hidden'
          animate='visible'
          id='body'
          flexDir={'column'}>
          <Text css={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            textOverflow: 'ellipsis'
          }} fontWeight={'bold'} >
            {formatToCapitalized(product.name, {
              trimTrailingWhiteSpaces: false
            })}
          </Text>

          <Flex
            fontWeight={'bold'}>
            <Text
              color={Number(product.initvalue) > Number(product.value) ? 'green.600' : 'red.600'}>({Number(product.initvalue) > Number(product.value) ? '+' : '-'}23%) <Text
                as='span'
                fontWeight={'normal'}
                color='gray'>do que no momento cadastrado</Text> </Text>
          </Flex>

        </MotionFlex>

        <MotionFlex
          id='footer'
          w='100%'
          // border='1px solid red'
          variants={itemAnimation}
          initial='hidden'
          align='center'
          justify='space-between'
          gridGap={2}
          animate='visible'>



          <MotionFlex
            variants={itemAnimation}
            initial='hidden'
            flexDir='column'
            gridGap='2'
            align='center'
            animate='visible'>

            <Flex gridGap={1}>
              <Icon as={MdMoneyOff} p='2px' bg='blue.300' borderRadius={"full"} fontSize={'xl'} />

            </Flex>

            <Text fontWeight={'bold'} >
              R$ {formatCurrencyWhileTyping(product.initvalue)}
            </Text>
          </MotionFlex>

          <MotionFlex
            variants={itemAnimation}
            initial='hidden'
            animate='visible'
            gridGap='2'
            align='center'
            flexDir='column'

          >

            <Flex gridGap={1}>
              <Icon as={MdAttachMoney} p='2px' bg='blue.300' borderRadius={"full"} fontSize={'xl'} />

            </Flex>

            <Text fontWeight={'bold'} >
              R$ {formatCurrencyWhileTyping(product.value)}
            </Text>
          </MotionFlex>

          <MotionFlex
            variants={itemAnimation}
            initial='hidden'
            flexDir='column'
            gridGap='2'
            align='center'
            animate='visible'>

            <Flex gridGap={1}>
              <Icon as={AiOutlineFall} p='2px' bg='blue.300' borderRadius={"full"} fontSize={'xl'} />

            </Flex>

            <Text fontWeight={'bold'} >
              % 20
            </Text>
          </MotionFlex>

          <MotionFlex
            variants={itemAnimation}
            initial='hidden'
            flexDir='column'
            gridGap='2'
            align='center'
            animate='visible'>

            <Flex gridGap={1}>
              <Icon p='2px' as={AiOutlineRise} bg='blue.300' borderRadius={"full"} fontSize={'xl'} />

            </Flex>

            <Text fontWeight={'bold'} >
              % 20
            </Text>
          </MotionFlex>



        </MotionFlex>

      </MotionFlex>

      {/* <MotionFlex
      maxW={['324px', '245px']}
      w='100%'
      h={['230px', '214px']}
      p='3'
      _hover={{ background: 'linear-gradient(to left bottom,  #12141a,  #181a21,  #1f2028, #23232c)', boxShadow: '5px 10px 10px #121217', borderRadius: '5% 5% 50% 50% / 2% 2% 2% 2%' }}
      onClick={() => handleEditProd(product)}
      cursor={'pointer'}
      transition='all ease 0.4s'
      border={Number(product.initvalue) > Number(product.value) ? '1px solid rgba(0, 100, 1, 0.4)' : '1px solid rgba(229, 62, 62, .4)'}
      boxShadow={'5px 10px 10px #121217'}
      gridGap={2}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.9 }}
      variants={containerFlex}
      flexDir={'column'} grenn
      initial='hidden'
      animate='visible'
      bgGradient={colorMode === 'dark' ? 'linear(to-tr,   #101218, #12141a, #14151b,  #181a21,  #1f2028, #23232c)' : 'linear(to-tr,  gray.400, gray.200, gray.100)'}
      borderRadius={'10px'}

    >

      <MotionFlex variants={itemAnimation} as='header' w='100%' mb='2' justify={'center'}>
        <Text css={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          textOverflow: 'ellipsis'
        }} fontWeight={'bold'} fontSize={'xl'} letterSpacing='1px'>
          {formatToCapitalized(product.name, {
            trimTrailingWhiteSpaces: false
          })}
        </Text>
      </MotionFlex>

      <MotionFlex variants={itemAnimation} w='100%' justify={'space-between'} fontSize={'xl'} fontWeight={'semibold'} h='100%'>
        <Flex fontSize={['xl', '15px']} h='100%' align={'center'} flexDir={'column'} justify={'space-between'} >
          <Flex flexDir={'column'} gridGap={2} >
            <Text >(Cadastro)</Text>
            <Flex>
              <Text>
                {formatCurrencyWhileTyping(product.initvalue)}
              </Text>
            </Flex>
          </Flex>
          <Flex align={'center'} justify={'space-between'} w='100%'>
            <BsGraphUp />

            <Text color={Number(product.initvalue) > Number(product.value) ? 'green.300' : 'red.300'}>
              20
            </Text>
          </Flex>
        </Flex>

        <Flex fontSize={['xl', '15px']} align={'center'} flexDir={'column'} justify={'space-between'}>
          <Flex flexDir={'column'} gridGap={2} >
            <Text fontSize={'md'}>(Atual)</Text>
            <Flex>
              <Text color={Number(product.initvalue) > Number(product.value) ? 'green.300' : 'red.300'}>
                {formatCurrencyWhileTyping(product.value)}
              </Text>
            </Flex>
          </Flex>

          <Flex align={'center'} justify={'space-between'} w='100%'>
            <Text>
              %
            </Text>
            <Text color={Number(product.initvalue) > Number(product.value) ? 'green.300' : 'red.300'}>
              20
            </Text>
          </Flex>

        </Flex>
      </MotionFlex>
    </MotionFlex >
 */}

    </>
  )
}
