import { Box, Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Select, Spinner, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { BaseButton } from '../../components/Buttons';

import { ModalWrapper } from '../../components/ModalWrapper';
import { useCallback, useContext, useEffect, useState } from 'react';
import { InputLabel } from '../../components/form/InputLabel';
import { api } from '../../services/api/api';
import { CardProduct } from './CardProduct';
import { Context } from '../../Context/authContext'
import { formatCurrencyWhileTyping } from '../../utils/formatCurrencyWhileTyping';
import { containerFlex, itemAnimation, MotionFlex } from '../../components/Animation/AnimationFlex';
import { Contexts } from '../../Context/productsContext';


export const Home = () => {

  const bg = useColorModeValue(
    'gray.100',
    'gray.800'
  )

  const { user } = useContext(Context)

  const { products, setProducts, loading, productsAux, setProductsAux } = useContext(Contexts)

  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const [editProd, setEditProd] = useState({})

  const [isEditProd, setIsEditProd] = useState(false)

  const toast = useToast()

  const [product, setProduct] = useState({
    userId: user.id,
    name: '',
    link: '',
    initValue: '',
  })


  const [showModal, setShowModal] = useState(false)



  const handleEditProd = (prod) => {
    setIsEditProd(!isEditProd)
    setEditProd(prod)
  }

  const handleChangeValueEdit = (e) => {
    setEditProd({ ...editProd, [e.target.name]: e.target.value })
  }

  // const handleGetAllProducts = useCallback(async () => {
  //   setLoadingPage(true)
  //   try {
  //     const response = await api.get(`products/user/${user.id}`)
  //     setProducts(response.data.data)
  //     setProductsAux(response.data.data)
  //   } catch (err) {
  //     return toast({
  //       title: `Erro ao buscar os produtos!`,
  //       status: 'error',
  //       isClosable: true,
  //     })
  //   }
  //   setLoadingPage(false)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user])


  const handleChangeValue = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value })
  }
  const handleCreateProduct = async (e) => {
    setIsLoadingButton(true)
    e.preventDefault()

    if (product.name.trim() === '') {
      setIsLoadingButton(false)
      return toast({
        title: `Preencha o nome do produto!`,
        status: 'warning',
        isClosable: true,
      })

    }

    if (product.link.trim() === '') {
      setIsLoadingButton(false)
      return toast({
        title: `Preencha o link do produto!`,
        status: 'warning',
        isClosable: true,
      })
    }

    if (product.initValue.trim() === '') {
      setIsLoadingButton(false)
      return toast({
        title: `Preencha o valor inicial do produto!`,
        status: 'warning',
        isClosable: true,
      })
    }

    const response = product.initValue.replace(/[^\d]+/g, '')
    product.initValue = Number(response)

    const productToSend = {
      userId: user.id,
      name: product.name,
      link: product.link,
      initValue: response
    }

    try {
      const response = await api.post('/products', productToSend)
      console.log(response.data.data)
      setProducts([response.data.data[0], ...products])
      setProductsAux([response.data.data[0], ...products])
      toast({
        title: `Produto cadastrado com sucesso!`,
        status: 'success',
        isClosable: true,
      })


      setProduct({
        userId: user.id,
        name: '',
        link: '',
        initValue: ''
      })


    } catch (err) {

      toast({
        title: `Erro ao adicionar o produto, tente novamente!`,
        status: 'error',
        isClosable: true,
      })
    }
    setShowModal(!showModal)
    setIsLoadingButton(false)
  }


  const handleEditProduct = async (e) => {
    setIsLoadingButton(true)
    e.preventDefault()


    if (editProd.name.trim() === '') {
      setIsLoadingButton(false)
      return toast({
        title: `Preencha o nome do produto!`,
        status: 'warning',
        isClosable: true,
      })

    }

    if (editProd.link.trim() === '') {
      setIsLoadingButton(false)
      return toast({
        title: `Preencha o link do produto!`,
        status: 'warning',
        isClosable: true,
      })
    }

    if (editProd.initvalue.trim() === '') {
      setIsLoadingButton(false)
      return toast({
        title: `Preencha o valor inicial do produto!`,
        status: 'warning',
        isClosable: true,
      })
    }

    const response = editProd.initvalue.replace(/[^\d]+/g, '')
    editProd.initvalue = Number(response)

    const productToSend = {
      id: editProd.id,
      name: editProd.name,
      link: editProd.link,
      initValue: response
    }

    try {
      const response = await api.patch('/products', productToSend)
      const productIndex = products.findIndex(product => product.id === productToSend.id)

      setProducts(products.map((item, index) => {
        const result = item;
        if (productIndex === index) {
          result.id = response.data.data[0].id;
          result.name = response.data.data[0].name;
          result.link = response.data.data[0].link;
          result.initvalue = response.data.data[0].initvalue
        }
        return result
      }))

      setProductsAux(productsAux.map((item, index) => {
        const result = item;
        if (productIndex === index) {
          result.id = response.data.data[0].id;
          result.name = response.data.data[0].name;
          result.link = response.data.data[0].link;
          result.initvalue = response.data.data[0].initvalue
        }
        return result
      }))

      toast({
        title: `Produto Editado com sucesso!`,
        status: 'success',
        isClosable: true,
      })

    } catch (err) {
      toast({
        title: `Erro ao editar o produto, tente novamente!`,
        status: 'error',
        isClosable: true,
      })
    }
    setIsEditProd(!isEditProd)
    setIsLoadingButton(false)
  }




  const handleFilterCards = (filterValue) => {
    if (filterValue === 'positivo') {
      setProductsAux(products.filter(product => product.initvalue > product.value))
    }
    if (filterValue === 'negativo') {
      setProductsAux(products.filter(product => product.initvalue <= product.value))
    }
    if (filterValue === '') {
      setProductsAux(products)
    }
  }


  return (
    <MotionFlex
      variants={itemAnimation}
      flexDir={'column'}
      initial='hidden'
      animate='visible'
    >
      <MotionFlex
        variants={itemAnimation}
        initial='hidden'
        animate='visible'
        align={'center'}
        mb='3'
        justify={'space-between'}
        gridGap={9}
      >

        <Flex>
          <Text fontSize={'xl'} fontWeight={'bold'}>
            Dashboard
          </Text>
        </Flex>


        <Flex w='100%' align={'flex-end'} justify={'flex-end'} gridGap={3}>

          <Select placeholder='Todos os resultados' border='2px' borderColor='gray' h='7' fontSize={'smaller'} p='0' w={['70%', '40%', '20%']} onChange={(e) => handleFilterCards(e.target.value)}>
            <option value='positivo'>Positivo</option>
            <option value='negativo'>Negativo</option>
          </Select>

          <Button variant={'outline'} fontSize={'md'} h='7' w='7' p='0' border='2px' borderColor='gray' _hover={{ background: 'gray.600' }} onClick={() => setShowModal(!showModal)} >
            +
          </Button>


        </Flex>
      </MotionFlex>
      <Flex flexWrap={'wrap'} >

        <Flex
          w='100%'
          p='2'

          justify={['center', 'center', 'normal']}
          flexWrap={'wrap'}
          gridGap={'5'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#2D2D39',
              borderRadius: '24px',
            },
          }}
          overflow={'auto'}
          minH='calc(100vh - 140px)'
          maxH='calc(100vh - 160px)'>


          {loading ? (
            <Flex w='100%' align={'center'} justify={'center'} mt='3'>
              <Spinner />
            </Flex>
          ) : (
            <>
              {productsAux.length > 0 ? (
                <>
                  {productsAux.map((item) => (
                    <>
                      <CardProduct product={item} key={item.id} handleEditProd={handleEditProd} />
                    </>
                  ))}
                </>
              ) : (
                <Flex align={'center'} w='100%' justify={'center'}>
                  <Text>Sem produtos nessa faixa!</Text>
                </Flex>
              )}

            </>
          )}

        </Flex>
      </Flex>

      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(!showModal)}>
        <ModalContent bg={bg} w={['350px', '700px',]}>
          <ModalHeader >Dados dos produtos</ModalHeader>
          <ModalCloseButton />
          <form autoComplete='off'>
            <ModalBody>
              <Stack spacing={3}>

                <InputLabel label='Nome do produto' valueInformation={product.name} placeholder='Digite o nome do produto RESUMIDO' nameInfo='name' onChange={handleChangeValue} />

                <InputLabel label='Link do produto' placeholder='Digite o link do produto' valueInformation={product.link} nameInfo='link' onChange={handleChangeValue} />

                <InputLabel label='Valor de cadastro' placeholder='Preço do produto' nameInfo='initValue' valueInformation={formatCurrencyWhileTyping(product.initValue)} onChange={handleChangeValue} />
              </Stack>


            </ModalBody>

            <ModalFooter justifyContent='flex-end'>
              <BaseButton firstLabel='Criar produto' h='8' fontSize="sm" onClick={handleCreateProduct} isLoading={isLoadingButton} type="submit" />
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalWrapper>




      <ModalWrapper isOpen={isEditProd} onClose={() => setIsEditProd(!isEditProd)}>
        <ModalContent bg={bg} w={['350px', '700px',]}>
          <ModalHeader >Editar Produto</ModalHeader>
          <ModalCloseButton />
          <form autoComplete='off'>
            <ModalBody>
              <Stack spacing={3}>

                <InputLabel label='Nome do produto' valueInformation={editProd.name} placeholder='Digite o nome do produto RESUMIDO' nameInfo='name' onChange={handleChangeValueEdit} />

                <InputLabel label='Link do produto' placeholder='Digite o link do produto' valueInformation={editProd.link} nameInfo='link' onChange={handleChangeValueEdit} />

                <InputLabel label='Valor de cadastro' placeholder='Preço do produto' nameInfo='initvalue' valueInformation={formatCurrencyWhileTyping(editProd.initvalue)} onChange={handleChangeValueEdit} />
              </Stack>
              {/* <InputLabel label='Cliente' valueInformation={info.cliente} nameInfo='cliente' bottomInfo='Nome do cliente' onChange={handleChangeValue} /> */}

            </ModalBody>

            <ModalFooter justifyContent='space-between'>

              <BaseButton firstLabel='Deletar produto' h='8' fontSize="sm" onClick={handleEditProduct} isLoading={isLoadingButton} type="submit" />
              <BaseButton firstLabel='Atualizar produto' h='8' fontSize="sm" onClick={handleEditProduct} isLoading={isLoadingButton} type="submit" />
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalWrapper>

    </MotionFlex >
  );
};
