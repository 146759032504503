

import { Button, Text } from '@chakra-ui/react'

export const NewButton = ({ firstLabel, secondLabel, ...rest }) => {

  return (
    <Button
      bg='#3CC68A'
      _hover={{ background: '#008037' }}
      type='submit'
      {...rest}

    >
      <Text >  {firstLabel} <br /> {secondLabel}</Text>
    </Button>
  )
}


