import { Flex, Text, Box, Avatar, IconButton, Icon, Input, HStack, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import { useContext } from 'react'
import { RiMenuLine, RiNotificationLine, RiSearchLine, RiUserAddLine } from 'react-icons/ri'
import { Context } from '../../Context/authContext'
import { useSidebar } from '../../Context/sidebar'
import { MotionFlex, containerFlex, headerFlexAnimation, itemAnimation } from '../Animation/AnimationFlex'
import logo from '../../shared/assets/images/Blue and Pink Abstract Grid Logo.svg'

export const Header = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { onOpen } = useSidebar()
  const bg = useColorModeValue(
    'gray.100',
    'gray.800'
  )
  const { user } = useContext(Context)

  return (
    <MotionFlex
      variants={itemAnimation}
      initial='hidden'
      animate='visible'
      w="100%"
      as='header'
      maxW={1480}
      h='7'
      mx='auto'
      mt='7'
      px='6'
      align='center'
    >
      {!isWideVersion && (
        <IconButton
          icon={<Icon as={RiMenuLine} />}
          variant='unstyled'
          fontSize='24'
          onClick={onOpen}
          aria-label="open navigation"
          mr='2'
        >
        </IconButton>
      )}
      <Flex w='240px'>
        <img src={logo} alt="teste" style={{ height: '50px', borderRadius: '15px' }} />
      </Flex>

      <Flex w='100%' justify={'flex-end'} gridGap={9} align={'flex-end'} >

        {/* {isWideVersion && (
          <Flex
            as='label'
            flex='1'
            py='2'
            px='4'
            ml='1'
            maxWidth={[220, 250]}
            alignSelf='center'
            position='relative'
            bg={bg}
            align={'center'}
            justify={'center'}
            borderRadius='full'
          >
            <Input
              variant='unstyled'
              placeholder='Buscar na plataforma'
              px='2'
              _placeholder={{ color: 'gray.400 ' }}

            />
            <Icon as={RiSearchLine} />
          </Flex>
        )} */}
        <Flex
          align='center'
        // ml='auto'
        >
          {/* <HStack
          spacing='6'
          mx='7'
          pr='5'
          py='1'
          borderRightWidth={1}
          // borderRightHeight={4}
          // borderRight='1px solid gray'
          borderColor='gray.700'
          color='gray.300'
          >

          <Icon as={RiNotificationLine} fontSize='20' />
          <Icon as={RiUserAddLine} fontSize='20' />
        </HStack> */}

          <Flex align='center'>
            <Box mr='4' textAlign='right'>
              <Text fontWeight={'bold'} isTruncated maxW={['100px', '200px', '300px']}>{user.name}</Text>
              <Text opacity={'0.5'} fontSize='small'>{user.type}</Text>
            </Box>
            <Avatar size='sm' name={user.name} />
          </Flex>
        </Flex>
      </Flex>

    </MotionFlex >

  )

}
