import React from 'react';

import { Header } from '../../components/Header';

import { Box, Flex, SimpleGrid } from '@chakra-ui/layout';
import { SideBar } from '../SideBar';

const PrivatePageLayout = ({ children }) => {
  return (
    <>
      <Flex direction={'column'} h='100vh' >
        <Header />
        <Flex w='100%' my='6' maxW='1480' mx='auto' px='6'  >
          <SideBar />
          <SimpleGrid flex='1' alignContent={'flex-start'}>
            {children}
          </SimpleGrid>
        </Flex>
      </Flex>


    </>
  );
};

export default PrivatePageLayout;
