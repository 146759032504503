import React from 'react';
import { Box } from '@chakra-ui/react';

// import PublicPageContent from './styles';

const PublicPageLayout = ({ children }) => {
  return <Box>{children}</Box>;
};

export default PublicPageLayout;
