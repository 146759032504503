import React from 'react'

import { Route, Switch, Router } from 'react-router-dom';
import { Login } from '../pages/Login';
import { Home } from '../pages/Home';
import { Products } from '../pages/Products';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
//import { SalesEdit } from '../pages/Products/SalesEdit'
import { SalesEditCard } from '../pages/Products/SalesEditCard'
import { MotoboySchedule } from '../pages/MotoboySchedule'
import { SalesPDF } from '../pages/Products/SalesPDF'
import history from '../services/history'
import { DashBoard } from '../pages/dashboard'
import { ListAllProducts } from '../pages/Products/ListAllProducts';
import { SignIn, SignIng } from '../pages/Login/subpages/SignIng';
import { Perfil } from '../pages/Perfil';
import { NewPassWord } from '../pages/Login/subpages/NewPassWord';

// import { Router } from 'react-router-dom';






export const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/signin" component={SignIn} />
        <PublicRoute exact path="/resetpass/:hash" component={NewPassWord} />


        {/* <PublicRoute exact path="/home" component={Home} /> */}

        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/perfil" component={Perfil} />

        {/* <Route exact path="/sales/SalesPDF/:id" component={SalesPDF} /> */}
        {/* <PrivateRoute exact path="/product/list" component={ListAllProducts} />
        <PrivateRoute exact path="/product" component={Products} />
        <PrivateRoute exact path="/sales/edit/" component={SalesEditCard} />
        <PrivateRoute exact path="/dash" component={DashBoard} />
        <PrivateRoute exact path="/sales/edit/:id" component={Products} />
        <PrivateRoute exact path="/sales/motoboy-schedule" component={MotoboySchedule} /> */}
      </Switch>
    </Router>


  );
};
