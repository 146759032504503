import { Box, Stack, Link as ChakraLink, Text, Icon } from "@chakra-ui/react"
import { RiContactsLine, RiDashboardLine } from "react-icons/ri"
import { ColorModeSwitcher } from "../theme/ColorModeSwitcher"
import { Link } from 'react-router-dom'
import { Context } from '../../Context/authContext'
import { useContext } from "react"
import { headerFlexAnimation, MotionFlex, sidebarAnimation } from "../Animation/AnimationFlex"




export const SidebarNav = () => {
  const { handleLogOut } = useContext(Context)

  console.log(window.location.pathname)
  return (


    <MotionFlex

      flexDir='column'
      gridGap='4'
      variants={headerFlexAnimation}
      initial='hidden'
      animate='visible'
      align='flex-start'>
      <MotionFlex
        variants={sidebarAnimation}
        initial='hidden'
        animate='visible'
      >
        <Stack spacing='4' mt='6' align='stretch'>

          <Box>
            <Text fontWeight={"bold"} color='gray.400' fontSize={'small'}>
              GERAL
            </Text>
          </Box>
          <Link to='/home'>
            <ChakraLink display='flex' align='center' color={window.location.pathname === '/home' ? 'blue.400' : ''}    >
              <Icon as={RiDashboardLine} fontSize='20' />
              <Text ml='4' fontWeight='medium'> Dashboard </Text>
            </ChakraLink>
          </Link>
          <Box>
            <Text fontWeight={"bold"} color='gray.400' fontSize={'small'}>
              PESSOAL
            </Text>
          </Box>

          <Link to='/perfil'>
            <ChakraLink display='flex' align='center' color={window.location.pathname === '/perfil' ? 'blue.400' : ''}   >
              <Icon as={RiContactsLine} fontSize='20' />
              <Text ml='4' fontWeight='medium'> Perfil </Text>
            </ChakraLink>
          </Link>
          <ChakraLink display='flex' onClick={handleLogOut} align='center'   >
            <Icon as={RiContactsLine} fontSize='20' />
            <Text ml='4' fontWeight='medium'> Sair </Text>
          </ChakraLink>
        </Stack>
      </MotionFlex>
      <MotionFlex
        flexDir='column'
        variants={sidebarAnimation}
        initial='hidden'
        animate='visible'
      >
        <Text fontWeight='bold' fontSize='small' color='gray.400'>
          Mudar o tema
        </Text>
        <ColorModeSwitcher />
      </MotionFlex>
    </MotionFlex>
  )
}
