
import { Flex, Text, Input, Button, InputGroup, Image, Tooltip, Box } from '@chakra-ui/react';
import fastyLeads from '../../../../shared/assets/images/fastyLeads.svg';
import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

import passwordIcon from '../../../../shared/assets/images/password.svg'
import emailIcon from '../../../../shared/assets/images/email.svg'

import login3Icon from '../../../../shared/assets/images/login3.svg'
import VectorIcon from '../../../../shared/assets/images/Vector.svg'

// signin

import { MotionFlex, containerFlex, itemAnimation } from '../../../../components/Animation/AnimationFlex';
import { api } from '../../../../services/api/api';
import history from '../../../../services/history';

export const SignIn = () => {

  const [loadingButton, setLoadingButton] = useState(false)
  const toast = useToast()
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  const handleChangeLogin = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }
  const [showPass, setShowPass] = useState(false)
  const handleClick = () => setShowPass(!showPass)



  const handleLogar = async () => {
    setLoadingButton(true)
    try {

      if (!user.name.trim()) {
        setLoadingButton(false)
        return toast({
          title: `Digite seu nome!`,
          status: 'error',
          isClosable: true,
        })
      }

      if (!user.email.trim()) {

        setLoadingButton(false)


        return toast({
          title: `Digite o E-mail!`,
          status: 'error',
          isClosable: true,
        })
      } else {

        const hasEmail = user.email.indexOf('@')

        if (hasEmail === -1) {
          setLoadingButton(false)
          return toast({
            title: `Digite um e-mail valido!`,
            status: 'error',
            isClosable: true,
          })
        }
      }



      if (!user.password.trim()) {
        setLoadingButton(false)
        return toast({
          title: `Digite sua senha!`,
          status: 'error',
          isClosable: true,
        })
      }



      if (!user.confirmPassword.trim()) {
        setLoadingButton(false)
        return toast({
          title: `Digite sua confirmação  de senha`,
          status: 'error',
          isClosable: true,
        })
      }

      if (user.confirmPassword !== user.password) {
        setLoadingButton(false)
        return toast({
          title: `As senhas não coincidem, por favor revise a senha`,
          status: 'error',
          isClosable: true,
        })
      }


      const object = {
        email: user.email,
        password: user.password,
        name: user.name
      }


      await api.post('/register', object)



      setLoadingButton(false)

      history.push('/')
      return toast({
        title: `Usuário criado com sucesso.`,
        status: 'success',
        isClosable: true,
      })





    } catch (err) {

      setLoadingButton(false)
      return toast({
        title: `Email ou senha incorretos`,
        status: 'error',
        isClosable: true,
      })
    }


  }


  return (
    <MotionFlex
      variants={containerFlex}
      initial='hidden'
      animate='visible'
      w='100vw'
      h='100vh'
      p={'2rem'}
    >


      <Flex w={['100%', '100%', '100%', '50%']}  >



        <MotionFlex
          variants={itemAnimation}

          flexDir={'column'}
          justify={'center'}
          minH="100%"
          align={'center'}

          w='100%'

          borderRadius={'10px'}


        >
          <Flex
            flexDir={'column'}
            justify={'center'}
            align={'center'}
            gridGap='1rem'
          >
            <Flex w='100%' alignItems="center" flexDir={'column'} justifyContent="space-evenly" >
              <Flex >
                <Image src={fastyLeads} alt="teste" style={{ height: '120px' }} />
              </Flex>

              <Text maxW='350px' color='#979797' fontSize={'14px'}>
                Organize seus leads de forma rápida, facil e intuitiva. Tenha seu historico e diversas outras informações na palma da sua mão.
              </Text>

            </Flex>


            <Flex w='100%' flexDir={'column'} align={'center'} justify={'space-between'} gridGap='1rem'>



              <Flex align='flex-start' flexDir='column' w='100%'>
                <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                  <Text>Nome </Text>

                </Flex>
                <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                  <InputGroup size='md'>
                    <Input
                      border='none'
                      type='text'
                      h='10'
                      p='1'
                      pl='4'
                      value={user.name}
                      name='name'
                      placeholder='Digite seu nome'
                      borderRadius='8px'
                      autocomplete="false"
                      onChange={handleChangeLogin}
                      focusBorderColor='none'
                      _focus={{
                        boxShadow: '0 0 0 0',
                        outline: 0

                      }}
                    />
                    <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                      <Text  > <Image src={emailIcon} height='24px' color='gray' /></Text>
                    </Flex>

                  </InputGroup>

                </Flex>


              </Flex>


              <Flex align='flex-start' flexDir='column' w='100%'>
                <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                  <Text>Email </Text>

                </Flex>
                <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                  <InputGroup size='md'>
                    <Input
                      border='none'
                      type='email'
                      h='10'
                      p='1'
                      pl='4'
                      value={user.email}
                      name='email'
                      placeholder='Digite seu Email'
                      borderRadius='8px'
                      autocomplete="false"
                      onChange={handleChangeLogin}
                      focusBorderColor='none'
                      _focus={{
                        boxShadow: '0 0 0 0',
                        outline: 0

                      }}
                    />
                    <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                      <Text  > <Image src={emailIcon} height='24px' color='gray' /></Text>
                    </Flex>

                  </InputGroup>

                </Flex>


              </Flex>

              <Flex align='flex-start' flexDir='column' w='100%'>
                <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                  <Text>Senha </Text>

                </Flex>
                <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                  <InputGroup size='md'>
                    <Input
                      border='none'
                      type={showPass ? 'text' : 'password'}
                      h='10'
                      p='1'
                      pl='4'
                      value={user.password}
                      name='password'
                      placeholder='Digite sua senha'
                      borderRadius='8px'
                      autocomplete="false"
                      onChange={handleChangeLogin}
                      focusBorderColor='none'
                      _focus={{
                        boxShadow: '0 0 0 0',
                        outline: 0

                      }}
                    />
                    <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                      <Text  >
                        <Tooltip label={!showPass ? 'Mostrar senha' : 'Ocultar senha'}>
                          <Box>
                            <Image src={passwordIcon} height='24px' color='gray' />
                          </Box>
                        </Tooltip>
                      </Text>
                    </Flex>

                  </InputGroup>

                </Flex>


              </Flex>

              <Flex align='flex-start' flexDir='column' w='100%'>
                <Flex fontSize={'md'} align={'center'} justify={'space-between'} w='100%'>
                  <Text>Confirmação da senha </Text>

                </Flex>
                <Flex w='100%' border='1px solid' borderColor={'#898989'} borderRadius={'8px'}>

                  <InputGroup size='md'>
                    <Input
                      border='none'
                      type={showPass ? 'text' : 'password'}
                      h='10'
                      p='1'
                      pl='4'
                      value={user.confirmPassword}
                      name='confirmPassword'
                      placeholder='Confirme sua senha'
                      borderRadius='8px'
                      autocomplete="false"
                      onChange={handleChangeLogin}
                      focusBorderColor='none'
                      _focus={{
                        boxShadow: '0 0 0 0',
                        outline: 0

                      }}
                    />
                    <Flex borderRadius='8px 0 0 8px' w='10' h='10' align='center' justify='center' onClick={handleClick} cursor='pointer'>
                      <Text  >   <Tooltip label={!showPass ? 'Mostrar senha' : 'Ocultar senha'}>
                        <Box>
                          <Image src={passwordIcon} height='24px' color='gray' />
                        </Box>
                      </Tooltip></Text>
                    </Flex>

                  </InputGroup>

                </Flex>


              </Flex>

              <Flex w='100%' flexDir={'column'}>
                <Button
                  bg='#71F697'
                  w='50%'

                  isLoading={loadingButton}
                  _hover={{ background: '#008037' }}
                  onClick={handleLogar}
                  type='submit'
                >
                  Cadastrar senha
                  <Image ml='3' src={VectorIcon} />
                </Button>



              </Flex>





            </Flex>
          </Flex>
        </MotionFlex>

      </Flex >


      <MotionFlex variants={itemAnimation} display={['none', 'none', 'none', 'flex']} w='50%' align={'center'} justify='center' >
        <Image src={login3Icon} height={['500px', '500px', '550px', '550px', '600px', '800px',]} objectFit={'cover'} />
      </MotionFlex>



    </MotionFlex >
  );
};
