export const data = [
  { value: "Afghanistan – Afeganistão" },
  { value: "Afghan – afegão" },

  { value: "Andorra – Andorra" },
  { value: "Andorran – andorrano" },

  { value: "Angola – Angola" },
  { value: "Angolan – angolano" },

  { value: "Antigua e Barbuda – Antígua e Barbuda" },
  { value: "Antiguan/Barbudan – antiguano" },

  { value: "Algeria – Argélia" },
  { value: "Algerian – argelino" },

  { value: "Argentina – Argentina" },
  { value: "Argentinian – argentino" },

  { value: "Armenia – Armênia" },
  { value: "Armenian – armênio" },

  { value: "Australia – Austrália" },
  { value: "Australian – australiano" },

  { value: "Austria – Áustria" },
  { value: "Austrian – austríaco" },

  { value: "Azerbaijan – Azerbaijão" },
  { value: "Azerbaijani – azeri" },

  { value: "The Bahamas – Bahamas" },
  { value: "Bahamian – bahamense" },

  { value: "Bangladesh – Bangladesh" },
  { value: "Bangladeshi – bangladês" },

  { value: "Barbados – Barbados" },
  { value: "Barbadian – barbadiano" },

  { value: "Bahrain – Barém" },
  { value: "Bahraini – baremita" },

  { value: "Belarus – Bielorrússia" },
  { value: "Belarusian – bielorrusso" },

  { value: "Belgium – Bélgica" },
  { value: "Belgian – belga" },

  { value: "Belize – Belize" },
  { value: "Belizean – belizenho" },

  { value: "Benin – Benim" },
  { value: "Beninese – beninense" },

  { value: "Bolivia – Bolívia" },
  { value: "Bolivian – boliviano" },

  { value: "Bosnia; Bosnia and Herzegovina – Bósnia; Bósnia e Herzegovina" },
  { value: "Bosnian – bósnio" },

  { value: "Botswana – Botsuana" },
  { value: "Motswana – bechuano" },

  { value: "Brazil – Brasil" },
  { value: "Brazilian – brasileiro" },

  { value: "Brunei – Brunei" },
  { value: "Bruneian – bruneano" },

  { value: "Bulgaria – Bulgária" },
  { value: "Bulgarian – búlgaro" },

  { value: "BurkinaFaso – BurkinaFaso" },
  { value: "Burkinabé – burquinense" },

  { value: "Burundi – Burundi" },
  { value: "Burundian – burundês" },

  { value: "Bhutan – Butão" },
  { value: "Bhutanese – butanense" },

  { value: "Cape Verde – Cabo Verde" },
  { value: "Cape Verdean – cabo-verdiano" },

  { value: "Cameroon – Camarões" },
  { value: "Cameroonian – camaronense" },

  { value: "Cambodia – Camboja" },
  { value: "Cambodian – cambojano" },

  { value: "Canada – Canadá" },
  { value: "Canadian – canadense" },

  { value: "Central African Republic – República Centro-Africana" },
  { value: "Central-african – centroafricano" },

  { value: "Chad – Chade" },
  { value: "Chadian – chadiano" },

  { value: "China – China" },
  { value: "Chinese – chinês" },

  { value: "Chile – Chile" },
  { value: "Chilean – chileno" },

  { value: "Cook Islands – Ilhas Cook" },
  { value: "Cook Islander – cookiano" },

  { value: "Colombia – Colômbia" },
  { value: "Colombian – colombiano" },

  { value: "Comoros – Comores" },
  { value: "Comoran – comoriano" },

  { value: "Costa Rica – Costa Rica" },
  { value: "Costa Rican – costa-riquenho" },

  { value: "Croatia – Croácia" },
  { value: "Croatian – croata" },

  { value: "Cuba – Cuba" },
  { value: "Cuban – Cubano" },

  { value: "Cyprus – Chipre" },
  { value: "Cypriot – cipriota" },

  { value: "Czech Republic – República Tcheca" },
  { value: "Czech – tcheco" },

  { value: "Democratic Republic of Congo – República Democrática do Congo" },
  { value: "Congolese – congolense" },

  { value: "Denmark – Dinamarca" },
  { value: "Danish – dinamarquês" },

  { value: "Djibouti – Djibuti" },
  { value: "Djiboutian – djibutiense" },

  { value: "Dominica – Dominica" },
  { value: "Dominican – dominiquense" },

  { value: "Dominican Republic – República Dominicana" },
  { value: "Dominican – dominicano" },

  { value: "East Timor – Timor Leste" },
  { value: "East Timorese – timorense" },

  { value: "Ecuador – Equador" },
  { value: "Ecuadorian – equatoriano" },

  { value: "Egypt – Egito" },
  { value: "Egyptian – egípcio" },

  { value: "El Salvador – El Salvador" },
  { value: "Salvadorean – salvadorenho" },

  { value: "England – Inglaterra" },
  { value: "English – inglês" },

  { value: "Equatorial Guinea – Guiné Equatorial" },
  { value: "Equatoguinean – guinéu-equatoriano" },

  { value: "Eritrea – Eritreia" },
  { value: "Eritrean – eritreu" },

  { value: "Estônia – Estônia" },
  { value: "Estonian – estoniano" },

  { value: "Fiji – Fiji" },
  { value: "Fijian – fijiano" },

  { value: "Finland – Finlândia" },
  { value: "Finnish – finlandês" },

  { value: "France – França" },
  { value: "French – francês" },

  { value: "Gabon – Gabão" },
  { value: "Gabonese – gabonense" },

  { value: "Gambia – Gâmbia" },
  { value: "Gambian – gambiano" },

  { value: "Georgia – Geórgia" },
  { value: "Georgian – geórgico" },

  { value: "Germany – Alemanha" },
  { value: "German – alemão" },

  { value: "Grenada – Granada" },
  { value: "Grenadian – granadino" },

  { value: "Greece – Grécia" },
  { value: "Greek – grego" },

  { value: "Guatemala – Guatemala" },
  { value: "Guatemalan – guatemalteco" },

  { value: "Guinea – Guiné" },
  { value: "Guinean – guineano" },

  { value: "Guinea–Bissau – GuinéBissau" },
  { value: "Bissau–guinean – guineense" },

  { value: "Guyana – Guiana" },
  { value: "Guyanese – guianense" },

  { value: "Haiti – Haiti" },
  { value: "Haitian – haitiano" },

  { value: "Holland – Holanda" },
  { value: "Dutch – holandês" },

  { value: "Honduras – Honduras" },
  { value: "Honduran – hondurenho" },

  { value: "Hungary – Hungria" },
  { value: "Hungarian – húngaro" },

  { value: "Iceland – Islândia" },
  { value: "Icelander – islandês" },

  { value: "India – Índia" },
  { value: "Indian – indiano" },

  { value: "Indonesia – Indonésia" },
  { value: "Indonesian – indonésio" },

  { value: "Iran – Irã" },
  { value: "Iranian – iraniano" },

  { value: "Ireland – Irlanda" },
  { value: "Irish – irlandês" },

  { value: "Israel – Israel" },
  { value: "Israeli – israelita" },

  { value: "Italy – Itália" },
  { value: "Italian – italiano" },

  { value: "Ivory Coast – Costa do Marfim" },
  { value: "Ivorian– costa - marfinense" },

  { value: "Jamaica – Jamaica" },
  { value: "Jamaican – jamaicano" },

  { value: "Japan – Japão" },
  { value: "Japanese – japonês" },

  { value: "Jordan – Jordânia" },
  { value: "Jordanian – jordão" },

  { value: "Kazakhstan – Cazaquistão" },
  { value: "Kazakh – cazaque" },

  { value: "Kenya – Quênia" },
  { value: "Kenyan – queniano" },

  { value: "Kiribati – Quiribati" },
  { value: "I-kiribati – quiribatiano" },

  { value: "Kyrgyzstan – Quirguistão" },
  { value: "Kyrgyzstani – quirguistanês" },

  { value: "Kwait – Kuwait" },
  { value: "Kwaiti – kuwaitiano" },

  { value: "Laos – Laos" },
  { value: "Laotian – laosiano" },

  { value: "Latvia – Letônia" },
  { value: "Latvian – letoniano" },

  { value: "Lebanon – Líbano" },
  { value: "Lebanese – libanês" },

  { value: "Lesotho – Lesoto" },
  { value: "Basotho – lesotiano" },

  { value: "Liberia – Libéria" },
  { value: "Liberian – liberiano" },

  { value: "Liechtenstein – Liechtenstein" },
  { value: "Liechtensteiner – liechtensteinense" },

  { value: "Lithuania – Lituânia" },
  { value: "Lithuanian – lituano" },

  { value: "Luxembourg – Luxemburgo" },
  { value: "Luxembourgish – luxemburguês" },

  { value: "Lybia – Líbia" },
  { value: "Lybian – líbio" },

  { value: "Macedonia – Macedônia" },
  { value: "Macedonian – macedônio" },

  { value: "Madagascar – Madagascar" },
  { value: "Malagasy – madagascarense" },

  { value: "Malaysia – Malásia" },
  { value: "Malaysian – malaio" },

  { value: "Malawi – Malaui" },
  { value: "Malawian – malauiano" },

  { value: "Maldives – Maldivas" },
  { value: "Maldivian – maldivo" },

  { value: "Mali – Máli" },
  { value: "Malian – maliano" },

  { value: "Malta – Malta" },
  { value: "Maltese – maltês" },

  { value: "Mauritius – Maurício" },
  { value: "Mauritian – mauriciano" },

  { value: "Mauritia – Mauritânia" },
  { value: "Mauritanian – mauritano" },

  { value: "Marshall Island – Ilhas Marshall" },
  { value: "Marshall Islander – marshallino" },

  { value: "Micronesia/Federated States of Micronesia – Estados Federados da Micronésia" },
  { value: "Micronesian – micronésio" },

  { value: "Mexico – México" },
  { value: "Mexican – mexicano" },

  { value: "Morocco – Marrocos" },
  { value: "Moroccan – marroquino" },

  { value: "Moldova – Moldavia" },
  { value: "Moldovan – moldávio" },

  { value: "Monaco – Mônaco" },
  { value: "Monacan – monegasco" },

  { value: "Mongolia – Mongólia" },
  { value: "Mongolian – mongol" },

  { value: "Montenegro – Montenegro" },
  { value: "Montenegrin – montenegrino" },

  { value: "Mozambique – Moçambique" },
  { value: "Mozambican – moçambicano" },

  { value: "Myanmar – Myanmar" },
  { value: "Burmese – birmanês" },

  { value: "Namibia – Namíbia" },
  { value: "Namibian – namibiano" },

  { value: "Nauru – Nauru" },
  { value: "Nauruan – nauruano" },

  { value: "Nepal – Nepal" },
  { value: "Nepali – nepalês" },

  { value: "New Zealand – Nova Zelândia" },
  { value: "NewZealander – neozelandês" },

  { value: "Nicaragua – Nicarágua" },
  { value: "Nicaraguan – nicaraguense" },

  { value: "Niger – Níger" },
  { value: "Nigerien – nigerino" },

  { value: "Nigeria – Nigéria" },
  { value: "Nigerian – nigeriano" },

  { value: "Niue – Niue" },
  { value: "Niuean – niuano" },

  { value: "North Korea – Coréia do Norte" },
  { value: "North korean – norte-coreano" },

  { value: "Norway – Noruega" },
  { value: "Norwegian – norueguês" },

  { value: "Oman – Omã" },
  { value: "Omani – omanense" },

  { value: "Palestine – Palestina" },
  { value: "Palestinian – palestino" },

  { value: "Pakistan – Paquistão" },
  { value: "Pakistanese – paquistanês" },

  { value: "Palau – Palau" },
  { value: "Palauan – palauense" },

  { value: "Panama – Panamá" },
  { value: "Panamanian – panamenho" },

  { value: "Papua New Guinea – Papua Nova Guiné" },
  { value: "Papua New Guinean – papuásio" },

  { value: "Paraguay – Paraguai" },
  { value: "Paraguayan – paraguaio" },

  { value: "Peru – Peru" },
  { value: "Peruvian – peruano" },

  { value: "Philippines – Philippines" },
  { value: "Philippine – filipino" },

  { value: "Poland – Polônia" },
  { value: "Polish – polonês" },

  { value: "Portugal – Portugal" },
  { value: "Portuguese – português" },

  { value: "Qatar – Catar" },
  { value: "Qatari – catarense" },

  { value: "Romania – Romênia" },
  { value: "Romanian – romeno" },

  { value: "Russia – Rússia" },
  { value: "Russian – russo" },

  { value: "Rwanda – Ruanda" },
  { value: "Rwandan – ruandês" },

  { value: "Samoa – Samoa" },
  { value: "Samoan – samoano" },

  { value: "Saint Lucia – Santa Lúcia" },
  { value: "Saint Lucian – santa-lucense" },

  { value: "Saint Kitts and Nevis – São Cristóvão e Nevis" },
  { value: "Kittian – são-cristovense" },

  { value: "San Marino – São Marino" },
  { value: "San Marinan – são-marinense" },

  { value: "Sao Tomé and Principe – São Tomé e Príncipe" },
  { value: "Sao Tomean – são-tomense" },

  { value: "Saint Vincent and the Grenadines – São Vicente e Granadinas" },
  { value: "Vicentinian – são-vicentino" },

  { value: "Scotland – Escócia" },
  { value: "Scottish – escocês" },

  { value: "Senegal – Senegal" },
  { value: "Senegalese – senegalense" },

  { value: "Serbia – Sérvia" },
  { value: "Serbian – sérvio" },

  { value: "Seychelles – Seicheles" },
  { value: "Seychellois – seichelense" },

  { value: "Sierra Leone – Serra Leoa" },
  { value: "Sierra Leonean – serra-leonês" },

  { value: "Singapore – Singapura" },
  { value: "Singaporean – singapurense" },

  { value: "Slovakia – Eslováquia" },
  { value: "Slovak – eslovaco" },

  { value: "Solomon Islands – Ilhas Salomão" },
  { value: "Solomon Islander – salomônico" },

  { value: "Somalia – Somália" },
  { value: "Somali – somali" },

  { value: "South Africa – África do Sul" },
  { value: "South African – sul–africano" },

  { value: "South Korea – Coréia do Sul" },
  { value: "Korean – coreano" },

  { value: "South Sudan – Sudão do Sul" },
  { value: "South Sudanese – sul-sudanense" },

  { value: "Spain – Espanha" },
  { value: "Spanish – espanhol" },

  { value: "Sri Lanka – Sri Lanka" },
  { value: "Sri Lankan – srilankês" },

  { value: "Sudan – Sudão" },
  { value: "Sudanese – sudanense" },

  { value: "Suriname – Suriname" },
  { value: "Surinamese – surinamês" },

  { value: "Swaziland – Suazilândia" },
  { value: "Swazi – suazi" },

  { value: "Sweden – Suécia" },
  { value: "Swedish – sueco" },

  { value: "Switzerland – Suíça" },
  { value: "Swiss – suíço" },

  { value: "Syria – Síria" },
  { value: "Syrian – sírio" },

  { value: "Tajikistan – Tadiquistão" },
  { value: "Tajiki – tajique" },

  { value: "Tanzania – Tanzânia" },
  { value: "Tanzanian – tanzaniano" },

  { value: "Thailand – Tailândia" },
  { value: "Thai – tailandês" },

  { value: "Togo – Togo" },
  { value: "Togolese – togolês" },

  { value: "Tonga – Tonga" },
  { value: "Tongan – tonganês" },

  { value: "Trinidad and Tobago – Trindade e Tobago" },
  { value: "Trinidadian – trinitário" },

  { value: "Tunisia – Tunísia" },
  { value: "Tunisian – tunisiano" },

  { value: "Turkmenistan – Turcomenistão" },
  { value: "Turkmen – turcomeno" },

  { value: "Turkey – Turquia" },
  { value: "Turkish – turco" },

  { value: "Tuvalu – Tuvalu" },
  { value: "Tuvaluan – tuvaluano" },

  { value: "Ukraine – Ucrânia" },
  { value: "Ukrainian – ucraniano" },

  { value: "Uganda – Uganda" },
  { value: "Ugandan – ugandês" },

  { value: "Uruguay – Uruguai" },
  { value: "Uruguayan – uruguaio" },

  { value: "United Arab Emirates – Emirados Árabes Unidos" },
  { value: "Emirati – árabe" },

  { value: "United Kingdom – Reino Unido" },
  { value: "British – britânico" },

  { value: "United States of America – Estados Unidos" },
  { value: "American – americano" },

  { value: "Uzbekistan – Usbequistão" },
  { value: "Uzbek – uzbeque" },

  { value: "Vanuatu – Vanuatu" },
  { value: "Ni-vanuatu – vanuatuano" },

  { value: "Venezuela – Venezuela" },
  { value: "Venezuelan – venezuelano" },

  { value: "Vietnam – Vietnã" },
  { value: "Vietnamese – vietnamita" },

  { value: "Wales – País de Gales" },
  { value: "Welsh – galês" },

  { value: "Yemen – Iêmen" },
  { value: "Yemeni – iemenita" },

  { value: "Zambia – Zâmbia" },
  { value: "Zambian – zambiano" },

  { value: "Zimbabwe – Zimbábue" },
  { value: "Zimbabwean – zimbabueano" },
]
